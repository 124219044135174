import React from "react";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { fetchAgents } from "../../../actions/agents";
import { connect } from "react-redux";
import { toggleAgentModal } from "../../../actions/modals";

// reactstrap components
import { Card, CardHeader, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import RemoteTable from "../common/RemoteTable";
import NewAgentModal from "views/modals/NewAgentModal";

class Agents extends React.Component {
  state = {
    alert: null,
    limit: 30,
    page: 1,
    size: 0,
  };
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };

  componentDidMount() {
    this.loadView();
  }

  loadView = () => {
    const { limit, page } = this.state;
    this.props.fetchAgents({ limit, page });
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: name == "limit" ? Number(value) : value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.count !== this.props.count && this.props.count) {
      this.setState({ size: this.props.count });
    }

    if (
      (prevState.limit && prevState.limit !== this.state.limit) ||
      (prevState.page && prevState.page !== this.state.page)
    ) {
      this.loadView();
    }
  }

  handleTableChange = (type, { page }) => {
    this.setState(() => ({
      page,
      data: this.props.agents,
      totalSize: this.props.count,
      // limit: Number(sizePerPage),
    }));
  };

  render() {
    const { agents } = this.props;
    const { page, limit } = this.state;
    const { history } = this.props;
    const rowEvents = {
      onClick: (e, row) => {
        history.push(`/admin/agents/${row._id}`);
      },
    };
    return (
      <>
        {this.state.alert}
        <SimpleHeader
          name="Agents"
          onNew={() => this.props.toggleAgentModal()}
          parentName=""
          actions={false}
        />
        <NewAgentModal />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Agents</h3>
                </CardHeader>
                <RemoteTable
                  page={page}
                  limit={limit}
                  size={this.props.count}
                  data={agents}
                  keyField={"_id"}
                  setLimit={(value) =>
                    this.setInput({ target: { name: "limit", value } })
                  }
                  handleTableChange={this.handleTableChange}
                  columns={[
                    {
                      dataField: "identity.name",
                      text: "Name",
                      sort: false,
                    },
                    {
                      dataField: "identity.email",
                      text: "Email",
                      sort: false,
                    },
                    {
                      dataField: "identity.phone",
                      text: "Phone",
                      sort: false,
                    },
                    {
                      dataField: "customers",
                      text: "Customers count",
                      sort: false,
                    },
                    {
                      dataField: "createdAt",
                      text: "Joined",
                      sort: false,
                    },
                  ]}
                  rowEvents={rowEvents}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    agents: state.agents.agents,
    loading: state.agents.loadingAgents,
    error: state.agents.agentsError,
    pages: state.agents.pages,
    count: state.agents.count,
  };
}

export default connect(mapStateToProps, {
  fetchAgents: (params) => fetchAgents(params),
  toggleAgentModal,
})(Agents);

import { formatDateTime } from "utils";
import * as types from "../types/transactions";

const initState = {
  transactions: [],
  currentTransaction: {},
  loadingTransactions: false,
  transactionsError: "",
  pages: 1,
  loadingSingleTransaction: false,
  singleTransactionError: "",
};

function transactionsReducer(state = initState, action) {
  switch (action.type) {
    case types.GET_ALL_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: true,
        transactionsError: "",
      };

    case types.GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        transactions: action.payload.orders.map((e) => ({
          ...e,
          serviceRequests: e.service_requests.length,
          parcels: e.parcels.length,
          createdAt: formatDateTime(e.createdAt),
        })),
        pages: action.payload.totalPages,
      };

    case types.GET_ALL_TRANSACTIONS_FAIL:
      return {
        ...state,
        loadingTransactions: false,
        transactionsError: action.payload,
      };
    case types.GET_SINGLE_TRANSACTION_START:
      return {
        ...state,
        loadingSingleTransaction: false,
        singleTransactionError: "",
      };

    case types.GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingSingleTransaction: false,
        currentTransaction: action.payload,
      };

    case types.GET_SINGLE_TRANSACTION_FAIL:
      return {
        ...state,
        loadingSingleTransaction: false,
        currentTransaction: {},
        singleTransactionError: action.payload,
      };
    default:
      return state;
  }
}

export default transactionsReducer;

import { formatDateTime } from "utils";
import * as types from "../types/applications";

const initState = {
  applications: [],
  loadingApplications: false,
  error: "",
  loadingSingleApplication: false,
  currentApplication: {},
  singleApplicationError: "",
  loadingApproval: false,
  approvalError: "",
  approvalSuccess: false,
};

function applicationsReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_APPLICATIONS:
      return {
        ...state,
        loadingApplications: true,
        error: "",
        approvalSuccess: false,
        approvalError: "",
      };

    case types.GET_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loadingApplications: false,
        ...action.payload,
        applications: payload.applications.map((a) => ({
          ...a,
          createdAt: formatDateTime(a.createdAt),
        })),
      };

    case types.GET_ALL_APPLICATIONS_FAIL:
      return {
        ...state,
        loadingApplications: false,
        error: action.payload,
      };

    case types.GET_SINGLE_APPLICATION_START:
      return {
        ...state,
        loadingSingleApplication: true,
        error: "",
        approvalSuccess: false,
      };

    case types.GET_SINGLE_APPLICATION_SUCCESS:
      return {
        ...state,
        loadingSingleApplication: false,
        currentApplication: payload,
      };

    case types.GET_SINGLE_APPLICATION_FAIL:
      return {
        ...state,
        loadingSingleApplication: false,
        singleApplicationError: payload,
      };

    case types.APPROVE_APPLICATION:
      return {
        ...state,
        loadingApproval: true,
        error: "",
        approvalSuccess: false,
      };

    case types.APPROVE_APPLICATION_SUCCESS:
      return {
        ...state,
        loadingApproval: false,
        currentApplication: payload,
        approvalSuccess: true,
      };

    case types.APPROVE_APPLICATION_FAILURE:
      return {
        ...state,
        loadingApproval: false,
        approvalError: payload,
      };
    default:
      return state;
  }
}

export default applicationsReducer;

import axiosInstance from "utils/axios";
import * as types from "../types/applications";
import { setError } from "./utils";

export const fetchApplications = (params) => (dispatch) => {
  dispatch({ type: types.GET_ALL_APPLICATIONS });
  axiosInstance()
    .get(`/applications`, { params })
    .then(({ data }) => {
      dispatch({
        type: types.GET_ALL_APPLICATIONS_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_ALL_APPLICATIONS_FAIL,
        payload: errors?.response?.message,
      });
    });
};

export const fetchSingleApplication = (id) => (dispatch) => {
  dispatch({ type: types.GET_SINGLE_APPLICATION_START });
  axiosInstance()
    .get(`/applications/${id}`)
    .then(({ data }) => {
      dispatch({
        type: types.GET_SINGLE_APPLICATION_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_SINGLE_APPLICATION_FAIL,
        payload: errors?.response?.message || "An error has occurred",
      });
    });
};

export const updateApplication = ({ status, id }) => (dispatch) => {
  dispatch({ type: types.APPROVE_APPLICATION });

  axiosInstance()
    .post(`/applications/${id}/approve`, { status })
    .then(({ data }) => {
      dispatch({
        type: types.APPROVE_APPLICATION_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.APPROVE_APPLICATION_FAILURE,
        payload: error?.response?.data?.message || "An error has occurred",
      });
      dispatch(
        setError(error?.response?.data?.message || "An error has occurred")
      );
    });
};

import axios from "axios";
import { saveItem } from "utils";
import { getItem } from "utils";
import store from "../store";
import { setError } from "../actions/utils";

const axiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_ENVIRONMENT == "local" ? "/admin" : "/api",
    // Uncomment when testing with staging environment
    /* baseURL:
    process.env.REACT_APP_ENVIRONMENT == "local"
      ? "http://207.154.202.43:1337/admin"
      : "/api", */
    timeout: 5000,
  });

  const { dispatch } = store;

  instance.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: `Bearer ${getItem("authToken")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        error.response.status !== 200 &&
        originalRequest.url === "/auth/refresh-token"
      ) {
        window.history.pushState("", "", "/auth/login");
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const { accessToken, refreshToken } = await instance.post(
            "/auth/refresh-token",
            {
              refresh_token: getItem("refreshToken"),
            }
          );
          saveItem("authToken", accessToken);
          saveItem("refreshToken", refreshToken);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken;

          return instance(originalRequest);
        } catch (error) {
          window.history.pushState("", "", "/auth/login");
          // eslint-disable-next-line no-self-assign
          window.location = window.location;
          return Promise.reject(error);
        }
      } else {
        dispatch(
          setError({
            message: error?.response?.data?.message || "An error occurred",
          })
        );
        // window.history.pushState("", "", "/auth/login");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosInstance;

import { format } from "date-fns";

export const saveItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const formatDay = (date) => format(date, "DD-MM-YYYY");

export const formatDateTime = (date) => {
  return `${new Date(date).toLocaleString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })} • ${new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Africa/Accra",
  })}`;
};

export const commaNumber = (num = 0) => Number(num).toLocaleString("en-US");

export const formatAmount = (num) => `₦ ${commaNumber(num)}`;

import React, { useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactToPrint from "react-to-print";
import { Search } from "react-bootstrap-table2-toolkit";

import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

const { SearchBar } = Search;
function RemoteTable(props) {
  const printRef = useRef();

  const {
    page,
    size,
    limit,
    setLimit,
    data = [],
    columns = [],
    keyField = "",
    handleTableChange = () => {},
    rowEvents = {},
  } = props;
  const A = paginationFactory({
    page,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    totalSize: size,
    sizePerPage: limit,

    // eslint-disable-next-line react/display-name
    sizePerPageRenderer: ({
      _options,
      _currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="limit"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                setLimit(e.target.value);
                onSizePerPageChange(page, e.target.value);
              }}
              value={limit}
            >
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  return (
    <div className="py-4 table-responsive">
      <Container fluid>
        <Row>
          <Col xs={12} sm={6}>
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5"
                    id="print-tooltip"
                    disabled={data.length < 1}
                  >
                    Print
                  </Button>
                )}
                content={() => printRef.current}
              />
            </ButtonGroup>
            <UncontrolledTooltip placement="top" target="print-tooltip">
              This will open a print page with the visible rows of the table.
            </UncontrolledTooltip>
          </Col>
          <Col xs={12} sm={6}>
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1 float-right"
            >
              <label>
                Search:
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  onSearch={(_text) => {}}
                />
              </label>
            </div>
          </Col>
        </Row>
      </Container>
      <BootstrapTable
        ref={printRef}
        remote
        keyField={keyField}
        data={data}
        onTableChange={handleTableChange}
        pagination={A}
        columns={columns}
        bordered={false}
        bootstrap4={true}
        striped={true}
        rowEvents={rowEvents}
        rowStyle={{ cursor: "pointer" }}
      ></BootstrapTable>
    </div>
  );
}

export default RemoteTable;

export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const GET_ALL_APPLICATIONS_FAIL = "GET_ALL_APPLICATIONS_FAIL";
export const GET_ALL_APPLICATIONS_SUCCESS = "GET_ALL_APPLICATIONS_SUCCESS";

export const GET_SINGLE_APPLICATION_START = "GET_SINGLE_APPLICATION_START";
export const GET_SINGLE_APPLICATION_SUCCESS = "GET_SINGLE_APPLICATION_SUCCESS";
export const GET_SINGLE_APPLICATION_FAIL = "GET_SINGLE_APPLICATION_FAIL";

export const APPROVE_APPLICATION = "APPROVE_APPLICATION";
export const APPROVE_APPLICATION_SUCCESS = "APPROVE_APPLICATION_SUCCESS";
export const APPROVE_APPLICATION_FAILURE = "APPROVE_APPLICATION_FAILURE";

import { formatDateTime } from "utils";
import * as types from "../types/agents";

const initState = {
  agents: [],
  currentAgent: {},
  loadingAgents: false,
  agentsError: "",
  pages: 1,
  loadingSingleAgent: false,

  singleAgentError: "",
};

function agentsReducer(state = initState, action) {
  switch (action.type) {
    case types.GET_ALL_AGENTS:
      return {
        ...state,
        loadingAgents: true,
        agentsError: "",
      };

    case types.GET_ALL_AGENTS_SUCCESS:
      return {
        ...state,
        loadingAgents: false,
        agents: action.payload.agents.map((e) => ({
          ...e,
          customers: e.customers?.length || 0,
          createdAt: formatDateTime(e.createdAt),
        })),
        pages: action.payload.totalPages,
        count: action.payload.count,
      };

    case types.GET_ALL_AGENTS_FAIL:
      return {
        ...state,
        loadingAgents: false,
        agentsError: action.payload,
      };

    case types.GET_SINGLE_AGENT_START:
      return {
        ...state,
        loadingSingleAgent: true,
        agentsError: "",
      };

    case types.GET_SINGLE_AGENT_SUCCESS:
      return {
        ...state,
        loadingSingleAgent: false,
        currentAgent: action.payload,
      };

    case types.GET_SINGLE_AGENT_FAIL:
      return {
        ...state,
        loadingSingleAgent: false,
        singleAgentError: "",
      };
    default:
      return state;
  }
}

export default agentsReducer;

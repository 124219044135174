import axiosInstance from "utils/axios";
import * as types from "../types/transactions";

export const fetchTransactions = (queryParams = { limit: 1 }) => (dispatch) => {
  dispatch({ type: types.GET_ALL_TRANSACTIONS });

  axiosInstance()
    .get(`/transactions`, { params: queryParams })
    .then(({ data }) => {
      dispatch({
        type: types.GET_ALL_TRANSACTIONS_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_ALL_TRANSACTIONS_FAIL,
        payload: errors?.response?.message,
      });
    });
};

export const fetchSingleTransaction = ({ id }) => (dispatch) => {
  dispatch({ type: types.GET_SINGLE_TRANSACTION_START });

  axiosInstance()
    .get(`/transactions/${id}`)
    .then(({ data }) => {
      dispatch({
        type: types.GET_SINGLE_TRANSACTION_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_SINGLE_TRANSACTION_FAIL,
        payload: errors?.response?.data?.message || "An error occurred",
      });
    });
};

import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// Reducers
import agentsReducer from "../reducers/agents";
import customersReducer from "../reducers/customers";
import transactionsReducer from "../reducers/transactions";
import modalsReducer from "../reducers/modals";
import dashboardReducer from "../reducers/dashboard";
import applicationsReducer from "../reducers/applications";
import utilReducer from "../reducers/util";

const rootReducer = combineReducers({
  agents: agentsReducer,
  customers: customersReducer,
  transactions: transactionsReducer,
  modals: modalsReducer,
  dashboard: dashboardReducer,
  applications: applicationsReducer,
  util: utilReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;

import axiosInstance from "utils/axios";
import * as types from "../types/dashboard";

export const fetchDashboardSummary = (params) => (dispatch) => {
  dispatch({ type: types.GET_DASHBOARD_SUMMARY });
  axiosInstance()
    .get(`/dashboard`, { params })
    .then(({ data }) => {
      dispatch({
        type: types.GET_DASHBOARD_SUMMARY_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_DASHBOARD_SUMMARY_FAILURE,
        payload: errors?.response?.message,
      });
    });
};

import * as types from "../types/modals";

const initState = {
  newAgentModal: false,
};

function modalsReducer(state = initState, action) {
  switch (action.type) {
    case types.TOGGLE_AGENT_MODAL:
      return {
        ...state,
        newAgentModal: !state.newAgentModal,
      };

    case types.CLOSE_ALL_MODALS:
      return {
        ...state,
        newAgentModal: false,
      };

    default:
      return state;
  }
}

export default modalsReducer;

import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "../Agents";
import Detail from "../agents/Detail";
// import TransactionDetails from './Merchants/TransactionDetails';

function Merchants() {
  return (
    <Switch>
      <Route exact path="/admin/agents" component={List} />
      <Route path="/admin/agents/:id" component={Detail} />
      {/* <Route
        path="/merchants/:mid/transaction/:id"
        component={TransactionDetails}
      /> */}
    </Switch>
  );
}

export default Merchants;

import axiosInstance from "utils/axios";
import * as types from "../types/agents";

export const fetchAgents = (params) => (dispatch) => {
  dispatch({ type: types.GET_ALL_AGENTS });
  axiosInstance()
    .get(`/agents`, { params })
    .then(({ data }) => {
      dispatch({
        type: types.GET_ALL_AGENTS_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_ALL_AGENTS_FAIL,
        payload: errors?.response?.message || errors,
      });
    });
};

export const fetchSingleAgent = ({ id }) => (dispatch) => {
  dispatch({ type: types.GET_SINGLE_AGENT_START });
  axiosInstance()
    .get(`/agents/${id}`)
    .then(({ data }) => {
      dispatch({
        type: types.GET_SINGLE_AGENT_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_SINGLE_AGENT_FAIL,
        payload: errors?.response?.data?.message || "An error has occurred",
      });
    });
};

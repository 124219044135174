import * as types from "../types/dashboard";

const initState = {
  totalTransactions: 0,
  totalAgents: 0,
  totalApplications: 0,
  totalCustomers: 0,
  totalClaims: 0,
  totalRewards: 0,
  loadingDashboard: false,
  error: "",
};

function dashboardReducer(state = initState, action) {
  switch (action.type) {
    case types.GET_DASHBOARD_SUMMARY:
      return {
        ...state,
        loadingDashboard: true,
        customersError: "",
      };

    case types.GET_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingDashboard: false,
        ...action.payload,
        customerChartData: action.payload.customerChartData.map((a) => ({
          label: a._id,
          data: a.count,
        })),
        a: {
          labels: action.payload.customerChartData
            // ?.flatMap((a) => [a, a, a, a, a, a, a, a, a])
            .map((a) => `${a._id}`),
          data: action.payload.customerChartData
            // ?.flatMap((a) => [a, a, a, a, a, a, a, a, a])
            .map((a) => a.count),
          datasets: [
            {
              label: "Customer Chart",
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              // action.payload.customerChartData
              //   ?.flatMap((a) => [a, a])
              //   .map((a) => a.count + Math.random() * 100),
            },
          ],
        },
      };

    case types.GET_DASHBOARD_SUMMARY_FAILURE:
      return {
        ...state,
        loadingDashboard: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default dashboardReducer;

import React from "react";

// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  numberChartConfig,
  chartConfig,
} from "variables/charts.js";
import { fetchDashboardSummary } from "actions/dashboard";
import { connect } from "react-redux";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };

  loadView = () => {
    this.props.getSummary({});
  };

  componentDidMount() {
    this.loadView();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     this.props.count &&
  //     prevProps.dashboard.totalCustomers !== this.props.dashboard.totalCustomers
  //   ) {
  //     chartExample1.data;
  //   }
  // }

  render() {
    const { dashboard = {} } = this.props;

    return (
      <>
        <CardsHeader
          name="Home"
          parentName="Dashboards"
          actions={false}
          summary={this.props.dashboard}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="8">
              <Card className="bg-default">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-light text-uppercase ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 text-white mb-0">
                        Customers Registered
                      </h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={() => ({
                        labels: dashboard.a?.labels || [],
                        datasets: [
                          {
                            label: "Customer Chart",
                            data: dashboard.a?.data || [],
                          },
                        ],
                      })}
                      options={chartConfig.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h5 className="h3 mb-0">Revenue</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={() => ({
                        labels: dashboard.a?.labels || [],
                        datasets: [
                          {
                            label: "Customer Chart",
                            data: dashboard.a?.data || [],
                          },
                        ],
                      })}
                      options={numberChartConfig.options}
                      className="chart-canvas"
                      id="chart-bars"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

export default connect(mapStateToProps, {
  getSummary: (params) => fetchDashboardSummary(params),
})(Dashboard);

import axiosInstance from "utils/axios";
import * as types from "../types/customers";

export const fetchCustomers = (params) => (dispatch) => {
  dispatch({ type: types.GET_ALL_CUSTOMERS });
  axiosInstance()
    .get(`/customers`, { params })
    .then(({ data }) => {
      dispatch({
        type: types.GET_ALL_CUSTOMERS_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_ALL_CUSTOMERS_FAIL,
        payload: errors?.response?.message,
      });
    });
};

export const fetchSingleCustomer = ({ id }) => (dispatch) => {
  dispatch({ type: types.GET_SINGLE_CUSTOMER_START });
  axiosInstance()
    .get(`/customers/${id}`)
    .then(({ data }) => {
      dispatch({
        type: types.GET_SINGLE_CUSTOMER_SUCCESS,
        payload: data,
      });
    })
    .catch((errors) => {
      dispatch({
        type: types.GET_SINGLE_CUSTOMER_FAIL,
        payload: errors?.response?.message,
      });
    });
};

import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";

function App(props) {
  const { error } = props;
  const notificationAlert = useRef();

  useEffect(() => {
    if (error) {
      notify("danger");
    }
    return () => {};
  }, [error]);

  const notify = (type) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type == "danger" ? "Sorry!" : "Success!"}
          </span>
          <span data-notify="message">
            {type == "danger" ? error : "Transaction updated!"}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          {/* <Route path="/" render={(props) => <IndexView {...props} />} /> */}
          <Redirect from="*" to="/admin" />
        </Switch>
      </BrowserRouter>
    </>
  );
}

// export default App;

function mapStateToProps(state) {
  return {
    error: state.util.error,
  };
}

export default connect(mapStateToProps)(App);

import * as types from "../types/util";

export const setError = ({ message }) => (dispatch) => {
  dispatch({ type: types.ON_ERROR, payload: message });
};

export const setSuccess = ({ message }) => (dispatch) => {
  dispatch({ type: types.ON_SUCCESS, payload: message });
};

export const clearMessages = () => (dispatch) => {
  dispatch({ type: types.ON_CLEAR });
};

import * as types from "../types/util";

const initState = {
  error: "",
  successMessage: "",
};

function utilReducer(state = initState, action) {
  switch (action.type) {
    case types.ON_CLEAR:
      return initState;

    case types.ON_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.ON_SUCCESS:
      return {
        ...state,
        successMessage: action.payload,
      };

    default:
      return state;
  }
}

export default utilReducer;

import { formatDateTime } from "utils";
import * as types from "../types/customers";

const initState = {
  customers: [],
  currentCustomer: {},
  loadingCustomers: false,
  customersError: "",
  pages: 1,
  loadingSingleCustomer: false,
  singleCustomerError: "",
};

function customersReducer(state = initState, action) {
  switch (action.type) {
    case types.GET_ALL_CUSTOMERS:
      return {
        ...state,
        loadingCustomers: true,
        customersError: "",
      };

    case types.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingCustomers: false,
        customers: action.payload.customers.map((e) => ({
          ...e,
          serviceAccounts: e.service_accounts.length,
          serviceRequests: e.service_requests.length,
          orders: e.orders.length,
          payments: e.payments.length,
          createdAt: formatDateTime(e.createdAt),
        })),
        pages: action.payload.totalPages,
      };

    case types.GET_ALL_CUSTOMERS_FAIL:
      return {
        ...state,
        loadingCustomers: false,
        customersError: action.payload,
      };

    case types.GET_SINGLE_CUSTOMER_START:
      return {
        ...state,
        loadingSingleCustomer: true,
        singleCustomerError: "",
      };

    case types.GET_SINGLE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingSingleCustomer: false,
        currentCustomer: action.payload,
      };

    case types.GET_SINGLE_CUSTOMER_FAIL:
      return {
        ...state,
        loadingSingleCustomer: false,
        currentCustomer: action.payload,
      };
    default:
      return state;
  }
}

export default customersReducer;

import React, { useEffect, useRef } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
// core components
import { connect } from "react-redux";
import { fetchSingleCustomer } from "actions/customers";

import NotificationAlert from "react-notification-alert";

const SingleCustomer = (props) => {
  const {
    getCustomer,
    match,
    customer,
    error,
    approvalError,
    approvalSuccess,
  } = props;

  useEffect(() => {
    getCustomer(match.params.id);
    return () => {};
  }, []);

  const notificationAlert = useRef();

  useEffect(() => {
    if (error || approvalError) {
      notify("danger");
    }
    return () => {};
  }, [error, approvalError]);

  useEffect(() => {
    if (approvalSuccess) {
      notify("success");
    }
  }, [approvalSuccess]);

  const notify = (type) => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type == "danger" ? "Sorry!" : "Success!"}
          </span>
          <span data-notify="message">
            {type == "danger" ? error || approvalError : "Customer updated!"}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <>
        <div className="header header-dark bg-bp pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    Single Customer
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    {/* <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem> */}
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Email
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {customer.identity?.email}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>

                {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button
                    color="info"
                    disabled={updating}
                    onClick={() => {
                      updateCustomer({
                        id: match.params.id,
                        status: "APPROVE",
                      });
                    }}
                    size="sm"
                  >
                    Approve
                  </Button>
                  <Button
                    disabled={updating}
                    onClick={() => {
                      updateCustomer({
                        id: match.params.id,
                        status: "DECLINE",
                      });
                    }}
                    color="danger"
                    size="sm"
                  >
                    Decline
                  </Button>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
      </>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Customer Details</h3>
              </CardHeader>
              <CardBody>
                <Row className="row-example">
                  <Col>
                    <div>
                      <h4 className="">Name</h4>
                      <p>{customer.identity?.name}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4 className="">Email</h4>
                      <p>{customer.identity?.email}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4 className="">Phone</h4>
                      <p>{customer.identity?.phone}</p>
                    </div>
                  </Col>
                  {/* <Col>
                    <div>
                      <h4 className="">Agent No.</h4>
                      <p>{customer.number}</p>
                    </div>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>

            {/* <Card>
              <CardHeader>
                <h3 className="mb-0">Address</h3>
              </CardHeader>
              <CardBody>
                <Row className="row-example">
                  <Col>
                    <div>
                      <h4>City</h4>
                      <p>{address?.city}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Country</h4>
                      <p>{address?.country}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>State</h4>
                      <p>{address?.state}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Street</h4>
                      <p>{address?.street}</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h3 className="mb-0">Bank Details</h3>
              </CardHeader>
              <CardBody>
                <Row className="row-example">
                  <Col>
                    <div>
                      <h4>Account Name</h4>
                      <p>{bank.account_name}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Account Number</h4>
                      <p>{bank.account_number}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Bank Name</h4>
                      <p>{bank.bank_name}</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}

            <Card>
              <CardHeader>
                <h3 className="mb-0">Performance Details</h3>
              </CardHeader>
              <CardBody>
                <Row className="row-example">
                  <Col>
                    <div>
                      <h4>Service Accounts</h4>
                      <p>{customer?.service_accounts?.length}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Orders</h4>
                      <p>{customer?.orders?.length}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Payments</h4>
                      <p>{customer?.payments?.length}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h4>Service Requests</h4>
                      <p>{customer?.service_requests?.length}</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    customer: state.customers.currentCustomer,
    loading: state.customers.loadingSingleCustomer,
    error: state.customers.singleCustomerError,
    updating: state.customers.loadingApproval,
    approvalError: state.customers.approvalError,
    approvalSuccess: state.customers.approvalSuccess,
  };
}

export default connect(mapStateToProps, {
  getCustomer: (id) => fetchSingleCustomer({ id }),
  //   updateCustomer: (body) => updateCustomer(body),
})(SingleCustomer);

// export default SingleApplication;
